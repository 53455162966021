<template>
  <v-container fluid align-start fill-height class="pt-0">
    <v-row justify="center" style="height: 100%">
      <v-col cols="12" class="pa-1 pt-2">
        <v-card tile elevation="1" style="height: 100%">
          <v-card-title class="pa-0 pr-1">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Журнал подій
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn depressed icon class="grey lighten-3" @click="show_filter = true">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-dialog v-model="show_detail" max-width="1000">
            <v-card>
              <v-card-title style="font-size: 1.1rem" class="mb-0 pb-0">
                <p class="pa-0 ma-0" v-html="parseRepresent(detail_row.represent)"></p>
              </v-card-title>
              <v-card-subtitle class="mt-0 pb-2">
                <v-chip-group :show-arrows="false">
                  <v-chip small label class="mr-2">
                    {{ detail_row.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
                  </v-chip>
                  <v-chip small label>{{ detail_row.owner_name }}</v-chip>
                </v-chip-group>
              </v-card-subtitle>
              <v-card-text>
                <template v-if="detail_row.crud_type === 'update'">
                  <div class="d-flex flex-nowrap">
                    <div style="flex: 0 0 50%" class="mr-2 pa-2 grey lighten-4">
                      <div class="row-title text-left">
                        <v-icon size="22" color="secondary" class="mr-2 pl-1">
                          mdi-page-previous-outline
                        </v-icon>
                        Попередні дані
                      </div>
                      <div>
                        <div v-for="(item, idx) in Object.keys(detail_row.event_data)"
                             class="event-row"
                             :key="idx">
                          <div class="left-col-1">{{ detail_row.event_data[item].field }}</div>
                          <div class="right-row-1">{{ detail_row.event_data[item].before }}</div>
                        </div>
                      </div>
                    </div>
                    <div style="flex: 1" class="ml-2 pa-2 grey lighten-4">
                      <div class="row-title text-left">
                        <v-icon size="22" color="success" class="mr-2 pl-1">
                          mdi-page-next-outline
                        </v-icon>
                        Поточні дані
                      </div>
                      <div>
                        <div v-for="(item, idx) in Object.keys(detail_row.event_data)"
                             class="event-row"
                             :key="idx">
                          <div class="left-col-1">
                            <div class="relation"></div>
                            {{ detail_row.event_data[item].field }}
                          </div>
                          <div class="right-row-1">{{ detail_row.event_data[item].after }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <div v-for="(item, idx) in Object.keys(detail_row.event_data)"
                         class="event-row px-0"
                         :key="idx">
                      <div class="left-col">{{ detail_row.event_data[item].field }}</div>
                      <div class="right-row">{{ detail_row.event_data[item].after }}</div>
                    </div>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="show_filter" max-width="700">
            <v-card>
              <v-card-text class="pt-6">
                <v-row>
                  <v-col cols="12" md="6" class="mb-0 pb-1">
                    <date_input v-model="table_filter.date_from" label="Дата з" :time="true" :required="false"/>
                  </v-col>
                  <v-col cols="12" md="6" class="mb-0 pb-1">
                    <date_input v-model="table_filter.date_to" label="Дата по" :time="true" :required="false"/>
                  </v-col>
                  <v-col cols="12" class="pt-2 pb-0" v-if="!flat_id">
                    <div style="color: rgba(69, 69, 69, 0.77); font-size: .84rem">Абонент/помешкання</div>
                    <div>
                        <AUC_FlatSearch
                          :flat_id="table_filter.flat_id"
                          :text_value="table_filter.person_id"
                          :text_value_key="'full_address'"
                          :input_style="'width: 99% !important;'"
                          thin
                          :required="false"
                          @selectChange="flatChange"
                          @afterClear="table_filter.clear = false"
                        />
                    </div>

                  </v-col>
                  <v-col cols="12">
                    <v-select
                        hide-details
                        v-model="table_filter.source_type"
                        :items="source_type_select"
                        label="Тип даних"
                        color="grey darken-1"
                        class="mt-0 pt-1"
                        clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        hide-details
                        v-model="table_filter.crud_type"
                        :items="crud_type_select"
                        label="Тип операції"
                        color="grey darken-1"
                        class="mt-0 pt-1"
                        clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        hide-details
                        v-model="table_filter.source_name"
                        :items="source_name_select"
                        label="Вид джерела"
                        color="grey darken-1"
                        class="mt-0 pt-1"
                        clearable
                    />
                  </v-col>
                </v-row>
              </v-card-text>
             <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn small depressed class="mr-2" @click="fetchWithFilter">Відібрати</v-btn>
               <v-btn small depressed class="mr-2" @click="clearForm">Очистити</v-btn>
               <v-btn small depressed @click="show_filter = false">Закрити</v-btn>
             </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              style="height: calc(100% - 40px)"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-full event-logger-table"
              @click:row="onRowClick"
          >
            <template v-slot:item.owner_name="{ item }">
              <v-chip label small>
                {{ item.owner_name }}
              </v-chip>
            </template>
            <template v-slot:item.represent="{ item }">
              <p v-html="parseRepresent(item.represent)" class="ma-0 pa-0"></p>
            </template>
            <template v-slot:item.icon="{ item }">
              <v-icon
                  :color="getIconData(false, item.crud_type)"
                  :size="22"
              >
                {{ getIconData(true, item.crud_type) }}
              </v-icon>
            </template>

            <template v-slot:item.create_date="{ item }">
              <span>
                {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userEventLoggerAPI from "@/utils/axios/user_event_logger"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  props: ['flat_id'],
  name: "UserEventLogger",
  components: {
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    date_input: () => import("@/components/accounting/input/document_date_input")
  },
  data() {
    return {
      crud_type_select: [
        { text: 'Створення', value: 'create' },
        { text: 'Редагування', value: 'update' },
        { text: 'Вилучення', value: 'delete' },
        { text: 'Проведення', value: 'conduct' },
        { text: 'Виконання', value: 'click' },
      ],
      source_type_select: [
        { text: 'Довідники', value: 'dictionary' },
        { text: 'Документи', value: 'document' },
        { text: 'Таблиці', value: 'table' },
        { text: 'Команди', value: 'command' },
      ],
      source_name_select: [
        { text: 'Група тарифу', value: 'tariff_group' },
        { text: 'Авторизація', value: 'login' },
        { text: 'Відсутні мешканці', value: 'absent_persons' },
        { text: 'Банк', value: 'bank' },
        { text: 'Банківські рахунки', value: 'bank_account' },
        { text: 'Будинок', value: 'building' },
        { text: 'Розрахунок по усіх абонентах', value: 'calc_by_flat' },
        { text: 'Обліковці', value: 'checker' },
        { text: 'Контакти', value: 'contacts' },
        { text: 'Склад сім’ї (мешканці)', value: 'family' },
        { text: 'Абоненти/помешканя', value: 'flat' },
        { text: 'Нормативні показники', value: 'flat_indicator' },
        { text: 'Послуги по абонентах', value: 'flat_service' },
        { text: 'Здача бази EPS', value: 'base_send' },
        { text: 'Тарифи по будинках', value: 'tariff_by_building' },
        { text: 'Тарифи по організаціях', value: 'tariff_by_organization' },
        { text: 'Завантаження оплат EPS', value: 'eps_get_payments' },
        { text: 'Вилучення оплат EPS', value: 'eps_remove_payments' },
        { text: 'Населені пункти', value: 'city' },
        { text: 'Вулиці', value: 'street' },
      ],
      table_filter: {
        row_num: 0,
        date_from: null,
        date_to: null,
        crud_type: null,
        source_type: null,
        source_name: null,
        flat_id: null,
        person_id: ''
      },
      show_detail: false,
      show_filter: false,
      detail_row: {
        create_date: null,
        owner_name: null,
        represent: null,
        event_data: {},
        crud_type: null,
        source_type: null,
      },
      items: [],
      tableHeader: [
        { text: '', value: 'icon', width: 24 },
        { text: 'Автор', value: 'owner_name', width: 220 },
        { text: 'Дата', value: 'create_date', width: 160 },
        { text: 'Представлення', value: 'represent' },
        { text: 'Абонент', value: 'flat_name', width: 300, align: 'right' },
      ],
      dialog_id: 0
    }
  },
  methods: {
    clearForm() {
      this.table_filter.date_to = null
      this.table_filter.date_from = null
      this.table_filter.crud_type = null
      this.table_filter.source_type = null
      this.table_filter.source_name = null
      this.table_filter.person_id = ''
      if (!this.flat_id) {
        this.table_filter.flat_id = null
      }
      this.table_filter.clear = true
    },
    flatChange(payload) {
     this.table_filter.flat_id = payload.value
     this.table_filter.person_id = payload.person_id
    },
    fetchWithFilter() {
      this.fetch()
      this.show_filter = false
    },
    onRowClick(payload) {
      this.detail_row.create_date = payload.create_date
      this.detail_row.owner_name = payload.owner_name
      this.detail_row.represent = payload.represent
      this.detail_row.crud_type = payload.crud_type
      this.detail_row.source_type = payload.source_type
      this.detail_row.event_data = JSON.parse(payload.event_data)

      this.show_detail = true
    },
    parseRepresent(text) {
      let new_text = "" + text
      new_text = new_text.replace('Вилучення елемента таблиці',
          '<span class="error--text font-weight-medium">Вилучення елемента таблиці!</span>'
      )
      new_text = new_text.replace('Вилучення елемента довідника',
          '<span class="error--text font-weight-medium">Вилучення елемента довідника!</span>'
      )
      new_text = new_text.replace('Редагування елемента таблиці',
          '<span class="secondary--text text--darken-1 font-weight-medium">Редагування елемента таблиці!</span>'
      )
      new_text = new_text.replace('Редагування елемента довідника',
          '<span class="secondary--text text--darken-1 font-weight-medium">Редагування елемента довідника!</span>'
      )
      new_text = new_text.replace('Виконання команди',
          '<span class="teal--text text--darken-1 font-weight-medium">Виконання команди!</span>'
      )
      new_text = new_text.replace('Створення елемента таблиці',
          '<span class="success--text text--darken-1 font-weight-medium">Створення елемента таблиці!</span>'
      )
      new_text = new_text.replace('Створення елемента довідника',
          '<span class="success--text text--darken-1 font-weight-medium">Створення елемента довідника!</span>'
      )
      const first = new_text.indexOf('!')
      const last = new_text.indexOf('.')
      if (first && last) {
        const for_replace = new_text.substring(first + 9, last)
        new_text = new_text.substring(0, first) + '!</span> <span class="font-weight-medium">"' + for_replace + '"</span>' + new_text.substring(last)
      }
      return new_text
    },
    getIconData(icon=true, crud_type) {
      if (crud_type === 'create') {
        return icon ? 'mdi-file-document-plus-outline' : 'success'
      }
      if (crud_type === 'update') {
        return icon ? 'mdi-file-document-edit-outline' : 'secondary'
      }
      if (crud_type === 'delete') {
        return icon ? 'mdi-file-document-remove-outline' : 'error'
      }
      if (crud_type === 'click') {
        return icon ? 'mdi-button-pointer' : 'teal'
      }
    },
    fetch() {
      const payload = Object.assign(this.table_filter)
      userEventLoggerAPI.get_all(payload)
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          });
    }
  },
  watch: {
    flat_id: {
      immediate: true,
      handler(payload) {
        this.table_filter.flat_id = payload
        this.fetch()
      }
    }
  },
  created() {
    if (!this.flat_id) {
      this.fetch()
    }
  }
}
</script>

<style scoped lang="scss">
  .row-title {
    font-size: 1rem;
    font-weight: 500;
    text-align: right;
    margin-bottom: 6px;
    padding: 6px;
    color: #2d2d2d;
  }
  .event-row {
    padding: 6px;
    margin-bottom: 4px;
    display: flex;
    color: #2d2d2d;

    .left-col {
      background-color: #e7e7e7;
      padding: 4px 4px 4px 10px;
      flex: 0 0 30%;
      font-weight: 500;
    }
    .right-row {
      flex: 1;
      background-color: #f3f3f3;
      padding: 4px 4px 4px 10px;
      font-size: .96rem
    }

    .left-col-1 {
      background-color: #dedede;
      padding: 4px 4px 4px 10px;
      flex: 0 0 30%;
      font-weight: 500;
      position: relative;

      .relation {
        position: absolute;
        left: -39px;
        top: 14px;
        width: 34px;
        height: 3px;
        background-color: #e5e5e5;
      }
    }
    .right-row-1 {
      flex: 1;
      background-color: #ffffff;
      padding: 4px 4px 4px 10px;
      font-size: .96rem
    }
  }
</style>